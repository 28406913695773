.background {
  /* background-color: #fcfaf2; */
  /* background-image: linear-gradient(135deg, #fbc2eb 0%, #a6c1ee 100%);
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%); */

  /* background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%); */
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
button:focus {
  outline: none !important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

button {
  display: inline-block;
  outline: none;
}
/* Reading page */

.lightbox__modal {
  /* text-decoration: dashed i !important;
  color: red !important; */
  border-bottom: 1px solid #003366;
  font-weight: bold;
  cursor: pointer;
}
