.container {
  display: flex;

  justify-content: center;
  align-items: center;
  width: calc(100% - 210px);

  /* width: inherit; */
  /* width: 600px; */
  color: #ffffff;
  /* background-color: ; */
  /* background-color: #263238; */
  height: 70px;
  position: fixed !important;
  /* left: 50%; */
  right: 0;
  /* bottom: 50px; */
  bottom: 0;
  /* transform: translateX(-50%); */
  transition: 0.3s;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 650px;
  padding: 0 10px;
  border-radius: 50px;
  background: white;
  /* box-shadow: 5px 5px 15px 5px #ffffffd3; */
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
}
.absoluteCSS {
  background: red;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.timeline {
  position: relative;
  width: 90%;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline__current,
.timeline__total {
  /* position: absolute; */
  /* top: 0; */
  /* font-size: 0.75rem; */
  font-size: 20px;
  min-width: 40px;
  color: #1d2a57;
}

.timeline__progress {
  position: relative;
  /* top: 0.375rem; */
  height: 0.375rem;
  width: calc(100% - 4.5rem);
  /* transform: translate(-50%, 0); */
  border-radius: 5px;
  overflow: hidden;
  background: #eceff1;
  margin: 0 15px;
}

.timeline__progress > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: #00adef;
}

.timeline__current {
  left: 0;
}

.timeline__total {
  right: 0;
}

/* play button */
.button {
  /* height: 100%; */
  height: 50px;

  width: 30px;
  /* margin-left: 15px; */
  border: 0;
  border-radius: 50px;
  transition: background 250ms;
  box-shadow: none !important;
  background: transparent;
  border: 1px solid #ffffff;
  color: white;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
button:focus {
  /* outline: none !important; */
}
.button:hover {
  /* background: #00adef; */
}

.button:active > .icon {
  transform: scale(0.85);
}
.button i {
  font-size: 18px;
  padding: 0;
  margin: 0;
  color: #1d2a57;
}
.playIconClass {
  font-size: 40px;
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #ffffff;
}

/* new */

.sider {
  width: 475px;
  cursor: pointer;
}

.fixWidthSidebar {
}
.transition {
  transition: 0.3s;
}

.playingColor {
  /* color: #00adef; */

  /* background: white; */
}
@media screen and (max-width: 992px) {
  .container {
    width: 100%;
  }
  .wrapper {
    width: 80%;
  }
  .button {
  }
  .sider {
    width: 70%;
  }
}
@media screen and (max-width: 576px) {
  .container {
    width: 100%;
  }
  .wrapper {
    width: 90%;
  }
  .button {
  }
  .sider {
    width: 70%;
  }
}
