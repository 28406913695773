.container {
  position: relative;
  max-width: 750px;
  width: 90%;
  border-bottom: solid 3px #5d2fc1;
  /* border-radius: 50px; */
  padding-top: 20px;
  z-index: 1;
  margin: auto;
}
.container button {
  outline: none;
  border: none;
  z-index: 1;
}
.definition_container,
.example_contaier {
  margin: 20px 0;
}

.btn {
  border-radius: 5px;
  cursor: pointer;
  width: 48px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn_container {
  display: flex;
  margin: 15px 0 5px 0;
}
.btn_container button {
  margin-right: 15px;
  padding: 5px;
  background-color: transparent;
}
.btn_translate {
  position: absolute;
  right: 0px;
  top: 40px;
}

/* word */
.txt_translate_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.txt_vocabulary {
  font-size: 30px;
  color: #1d2a57;
  font-weight: 400;
  align-items: center;
}
.txtTrasnlateVoc {
  transition: 0.3s;
  display: inline-block;
  margin-left: 20px;
  font-size: 30px;
  color: #000000;
  font-weight: 400;
  opacity: 0;
}

.addList_line {
  position: relative;
  margin: 7px 0;
  margin-bottom: 10px;
  border-top: solid thin #fec400;
}

.addList_line button {
  position: absolute;
  right: 0;
  top: -12px;
}
.txt_translate {
  /* color: rgba(0, 0, 0, 0.65); */
  color: #be0000;
  opacity: 0;
  transition: transform 0.2s;
  height: auto;
  transform: translateY(-100%);
}
.txt_spelling {
  font-size: 20px;
  color: #1d2a57;
  font-weight: 600;
}
.line_spelling {
  margin: 0 7px;
}
.show_txt_translate {
  transition: opacity 0.2s;
  transition: 0.5s;
  opacity: 1;
  margin: 10px 0 20px 0;

  /* background: red; */
  transform: translateY(0);
}
.showOff_txt_translate {
  transition: 0.3s;
  height: 0;
  opacity: 0;
  /* transition: translateY 0.3s 2s; */
  transform: translateY(0);
}
@media screen and (max-width: 576px) {
  .container {
    position: relative;
    width: 100%;
    border-bottom: solid 3px #5d2fc1;

    padding: 10px;
    z-index: 1;
  }
  .txt_vocabulary {
    font-size: 22px;
    color: #1d2a57;
    font-weight: 400;
  }

  .txt_definition,
  .txt_example {
    font-size: 16px;
  }
  .txt_definition {
    margin-top: 10px;
  }
  .txt_definition::first-letter,
  .txt_example::first-letter {
    text-transform: capitalize;
  }
}
@media screen and (max-width: 768px) {
  .txt_translate_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .txt_spelling {
    font-size: 18px;
  }
  .line_spelling {
    display: none;
  }
}
