.container {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* height: 100%; */
  height: calc(100vh - 65px);
  position: relative;
  width: 900px;
  margin: 0 auto;
  /* background: #fcfaf2; */
}
.container i {
  cursor: pointer;
}
.table {
  width: 100%;
  text-align: left;
  color: #424242;
}
.table tr {
  height: 50px;
}
.table thead td {
  font-size: 20px;
}
.tbody {
  font-size: 16px;
}
.tbody td {
  padding-left: 5px;
}
.trTagTbody {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.trTagTbody i {
  border: 1px solid black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 23px;
  cursor: pointer;
  position: relative;
}

.trTagTbody i:hover {
  /* transform: scale(1.1); */
  transition: 0.3s;
  position: relative;
  overflow: hidden;
}
/* .trTagTbody i:active::after {
  animation: rippleOff 300ms forwards ease-out;
} */
.highlightRow {
  color: #3682f9;
  /* background-color: #E3EEFA; */
}

.highlightRow .highlightBtn i {
  background-color: #3682f9;
  color: #ffffff;
  border: #ffffff;
}
.highlightRow .deleteBtn i {
  border: 1px solid black;
  color: #424242;
}
.deteletRow {
  color: #dc004e;
  text-decoration: line-through;
}
.deteletRow .deleteBtn i {
  background-color: #dc004e;
  border: #dc004e;
  color: #ffffff;
}

.deteletRow .highlightBtn i {
  border: 1px solid black;
  color: #424242;
  /* position: absolute;
  left: 50%;
  top: 50%; */
  transform: translateX(-50%) translateY(-50%);
}
.highlightBtn,
.deleteBtn {
  /* position: relative; */
  width: 15%;
  /* margin-right: auto; */
}
.deleteBtn {
  /* position: relative; */
  /* width: 15%; */
  text-align: left;
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
}
.btn_container {
  margin-top: 15px;
}
.btn_container button {
  cursor: pointer;
}
/* confirm box */
.confirm_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 32, 32, 0.55);
  /* opacity: 0.55; */
  overflow: hidden;
  z-index: 2;
  display: none;
}
.container_confirm_box {
  position: fixed;
  top: 25%;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 400px;
  opacity: 1;
  background-color: #ffffff;
  transform: translateX(-50%);
  padding: 25px;
  border-radius: 5px;
}
.confirm_box .btn_container {
  display: flex;
}

.confirm_box button {
  outline: red;
  height: 30px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  border: none;
  border-radius: 15px;
}
.confirm_box .cofirm_btn {
  margin-right: 5px;
  background-color: #1d2a57;
  color: white;
  margin-right: 10px;
}
.confirm_box button:active {
  border: red;
  outline: none;
}

/* pagination bar */

.pagination_container {
  /* width: 100%;
   */
  /* margin-top: 20px; */
  /* width: 900px;  */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  /* position: absolute;
  bottom: 20px;
  right: 0; */
}
@media screen and (max-width: 992px) {
  .container {
    width: 100%;
    padding: 10px;
  }
  .table tr {
    height: 50px;
    width: 100%;
    word-wrap: normal;
  }
}
@media screen and (max-width: 576px) {
  .table thead th {
    font-size: 15px;
    width: 30px;
    font-weight: bold;
  }

  .table .tbody td {
    font-size: 16px;
    width: 30%;
  }
  .highlightBtn,
  .deleteBtn {
    width: 10% !important;
  }

  .container_confirm_box {
    width: 80%;
    top: 30%;
  }
}

.no__data__message {
  margin-top: 3rem;
}
