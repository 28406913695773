.btn_translate {
  width: 50px;
  height: 25px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  /* transition: 0.2s; */
}
.btn_translate::after {
  display: none;
  content: "Translate to Vietnamese";
}
.btn_translate:hover::after {
  display: block;
  position: absolute;
  width: max-content;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
.btn_translate:hover {
  /* transform: scale(1.1); */
}
@media screen and (max-width: 576px) {
  .btn_translate {
    width: 40px;
    height: 20px;
  }
}
