.container {
  height: calc(100vh - 65px);
  width: 100%;
  /* max-width: 1140px; */
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  z-index: 1;
  /* background: red; */
  padding-top: 70px;
}
.progress_line {
  height: 16px;
  max-width: 950px;
  width: 90%;
  min-height: auto;
  min-width: auto;
  border-radius: 8px;
  background-color: #e5e5e5;
  /* overflow: hidden; */
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px; */
  margin: 20px auto;
  margin-top: 70px;
  position: relative;
}
.progress_line_correct {
  background-color: #58a700;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transition: 0.5s;
  border-radius: 8px;
}
/* question_box */

.question_box {
  /* position: absolute; */

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 50px;
  z-index: 2;
  /* background: red; */
}
.question_box .question {
  font-size: 30px;
  color: #1d2a57;
  font-weight: 400;
}
.overlay {
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  display: none;
  font-size: 50px;
}
/* notification */
.show__notification_container {
  height: 140px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  /* width: 200px; */
  border-top: 2px solid;
  display: flex;
}
.show__notification {
  /* height: 140px; */
  margin: 0 auto;
  /* max-width: 900px; */
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
}
.choice_btn_container {
  width: 100%;
  /* height: 140px; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn {
  width: 150px;
  height: 50px;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  outline: none;
}
/* success */
.choice_btn_container_success {
  padding: 0 30px;
  background-color: #b8f28b;
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 950px; */
  margin: auto;
}
.choice_btn_container_success .excellent {
  display: flex;
  align-items: center;
}

.excellent i {
  font-size: 40px;
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  line-height: 80px;
  color: #58a700;
  /* font-weight: 500; */
}
.excellent span {
  font-size: 24px;
  margin-left: 15px;
}
button.next {
  background-color: #58a700;
  color: #ffffff;
  font-size: 17px;
}
/* fail */
.choice_btn_container_fail {
  background-color: #ffc1c1;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 950px;
  width: 90%;
  margin: auto;
}
.incorrect {
  display: flex;
  align-items: center;
}
.incorrect i {
  font-size: 40px;
  display: inline-block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #ffffff;
  text-align: center;
  line-height: 80px;
  color: #ea2b2b;
}
.incorrect div {
  margin-left: 15px;
  /* width: 500px; */
}
.incorrect div span {
  font-size: 24px;
}
.incorrect div p span {
  font-size: 20px;
}
.incorrect div p {
  font-size: 17px;
  color: #ea2b2b;
}
.choice_btn_container_fail button.next {
  background-color: #ea2b2b;
  color: #ffffff;
  font-size: 17px;
}

.multiple_container {
  /* background: red; */
  /* height: 100vh; */
  /* width: 100vw; */
  height: calc(100vh - 65px);
  width: 100%;
  /* padding-top: 70px; */
  overflow: hidden;

  /* position: relative; */
  /* z-index: -1; */
}
.showStatic_container {
  position: absolute;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row-reverse;
  height: max-content;
  color: #1d2a57;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
}

.showStatic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: max-content;
  color: #1d2a57;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
  margin-right: 10px;
}
.showStatic p {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 992px) {
  .multiple_container,
  .container {
    height: calc(100vh - 60px);
  }
  .container {
    padding: 30px;
  }
  .question_box {
    margin-bottom: 50px;
  }
  .progress_line {
    margin-top: 50px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 30px 0px;
  }
  .multiple_container {
    padding: 10px;
    /* padding-top: 50px; */
  }
  .progress_line {
    height: 16px;
    max-width: 950px;
    width: 90%;
  }
  .question_box .question {
    padding: 10px;
    font-size: 20px;
    color: #1d2a57;
    font-weight: 400;
  }
  .progress_line {
    margin-top: 30px;
  }
  .question_box {
    margin: 10;
  }
  .choice_btn_container_success {
    height: auto;
  }
  .choice_btn_container_fail {
    height: auto;
  }
}
@media screen and (max-width: 374px) {
  .question_box {
    margin-top: 20px;
    overflow: scroll;
    justify-content: end;
    margin-bottom: 60px;
  }
  .show__notification_container {
    height: 20%;
  }
  .progress_line {
    height: 10px;
  }
  .showStatic_container {
    bottom: 10px;
  }
  .btn {
    width: 120px;
    height: 40px;
  }
}
