.background {
  background-color: #fcfaf2;
  /* background: red; */
  width: 100%;
  height: calc(100vh - 65px);
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0;
}
.title {
  color: white;
  /* top: 20px; */
  text-align: center;
  font-size: 66px;
  border: dotted 4px white;
  background-color: #52bab3;
  /* width: 50%; */
  width: max-content;
  padding: 35px;
  margin: auto;
}
.flashMessage {
  margin: auto;
  font-size: 46px;
  color: tomato;
  text-align: center;
}
.box_container {
  width: 900px;
  height: 500px;
  background-color: #f4f1de;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 40px; */
  justify-content: space-between;
  padding: 50px;
}

.box_container .button_question {
  font-size: 25px;
  margin-top: 20px;
  height: 58px;
  width: 320px;
  padding: 10px 24px 10px 24px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  word-spacing: 0px;
  background-color: #ffb703;
  border: none;
  border-radius: 16px;
  color: #3d405b;
  outline: none;
  cursor: pointer;
}
.box_question {
  margin-bottom: 20px;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.question {
  font-size: 40px;
}
.answer_enslish,
.answer_vietnamese {
  margin: 20px 0;
  margin-bottom: 30px;
}
.answer {
  font-size: 20px;
}
.button_answer_box {
  display: flex;
  width: 90%;
  justify-content: space-between;
}
.box_answer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button_answer_box .button_content {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button_answer_box .button_content button {
  width: 100%;
  font-size: 20px;
  margin-top: 20px;
  height: 48px;
  width: 200px;
  padding: 10px 24px 10px 24px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  word-spacing: 0px;
  border: none;
  border-radius: 16px;
  color: #3d405b;
  outline: none;
  cursor: pointer;
  margin-bottom: 15px;
}
.button_answer_box .button_content p {
  text-align: center;
}
.button_easy {
  background-color: #4cc9f0;
}
.button_medium {
  background-color: #52b788;
}
.button_hard {
  background-color: #f94144;
}
.notification {
  text-align: center;
}
@media screen and (max-width: 992px) {
  .box_container {
    width: 100%;
    height: 100%;
  }
  .box_container .button_question {
    max-width: 320px;
    width: 80%;
    font-size: 24px;
  }
  .question {
    font-size: 35px;
  }
  .title {
    height: 20%;
    font-size: 50px;
    width: max-content;
  }
}
@media screen and (max-width: 576px) {
  .container {
    width: 100%;
  }
  .background h1 {
    color: white;
    text-align: center;
    font-size: 35px;
    border: dotted 4px white;
    background-color: #52bab3;
    width: 100%;
    padding: 20px;
    margin: auto;
  }
  .box_container {
    width: 100%;
    height: 100%;
    /* height: 500px; */
    height: 100%;
    /* background-color: #f4f1de; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    justify-content: space-between;
    /* padding: 20px; */
  }
  .button_answer_box {
    padding: 20px;
  }
  .box_container .button_question {
    max-width: 280px;
    width: 80%;
    font-size: 20px;
  }
  .container .box_answer {
    width: 100%;
  }
  .container .box_answer .button_answer_box {
    padding: 0;
    width: 100%;
  }
  .button_answer_box .button_content {
    width: 30%;
  }
  .button_answer_box .button_content button {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .answer_english,
  .answer_vietnamese {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
