.item {
  width: 190px;
  height: 250px;
  display: flex;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin: 20px;
  border-radius: 8px;
  padding: 20px;
  color: #3d405b;
  font-size: 20px;
  font-weight: 500;
  background-color: #ffd166;
  transition: all 0.2s;
  cursor: inherit;
  padding-top: 25px;
  margin-right: auto;
}
.lockIcon {
  position: absolute;
  top: 5px;
  left: 5px;
  /* transform: translate(-50%); */
}
.cssIsLock .item {
  background-color: #e5e5e5 !important;
  font-size: 60px;
}
svg {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
}

.item p {
  position: absolute;
  bottom: 5px;
  cursor: inherit;
  z-index: 1;
}
.item:hover {
  transform: scale(1.05);
  cursor: inherit;
}
/* .isLockCss {
} */

@media screen and (max-width: 576px) {
  /* style changes when the screen gets smaller */
  .item {
    width: 150px;
    height: 120px;
    display: flex;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    padding: 5px;
    color: #3d405b;
    font-size: 20px;
    font-weight: 500;
    background-color: #ffd166;
    transition: all 0.3s;
    padding-top: 30px;
  }
  .item svg {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -15px;
    height: 50px;
  }
  .item p {
    position: absolute;
    bottom: 5px;
    cursor: inherit;
    margin-bottom: 1px !important;
    font-size: 20px;
  }
  .item span {
    font-size: 16px;
  }
  .lockIcon {
    font-style: 10px;
  }
}
