.alert {
  display: flex;
  background: #1ba345;
  padding: 1% 1%;
  min-width: 18%;
  position: fixed;
  z-index: 999;
  right: 0;
  /* bottom: 14.5%; */
  bottom: 140px;
  border-radius: 4px;
  border-left: 8px solid #2b3647;
  overflow: hidden;
  opacity: 1;
  pointer-events: auto;
}
.alert.show {
  animation: show_slide 2.5s ease forwards;
}
@keyframes show_slide {
  0% {
    transform: translateX(100%);
  }
  15% {
    transform: translateX(-10%);
  }
  30% {
    transform: translateX(0%);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(0%);
  }
  90% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(100%);
  }
}
.alert .fa-check-circle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #2b3647;
  font-size: 200%;
}
.alert .msg {
  margin-left: 15%;
  padding: 0 1%;
  font-size: 15px;
  color: #2b3647;
}

@media screen and (max-width: 576px) {
  .alert {
    display: flex;
    background: #1ba345;
    padding: 1% 1%;
    min-width: 18%;
    position: fixed;
    z-index: 999;
    right: 0;
    bottom: 16%;
    border-radius: 4px;
    border-left: 8px solid #2b3647;
    overflow: hidden;
    opacity: 1;
    pointer-events: auto;
  }
  .alert .fa-check-circle {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #2b3647;
    font-size: 200%;
  }
  .alert .msg {
    margin-left: 15%;
    padding: 0 1%;
    font-size: 14px;
    color: #2b3647;
  }
}
