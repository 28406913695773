.background {
  background-color: #f8f4e1;
  width: 100%;
  /* height: 200px; */
  min-height: calc(100vh - 65px);
}
.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px 20px;
}
.flex {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  /* justify-content: flex-start; */

  /* padding: 20px; */
}

.stretch {
  flex-grow: 1;
}
.dayExpire {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.dayExpire .spanTag {
  font-weight: bold;
  margin-left: 5px;
  margin-right: 2px;
}

@media screen and (max-width: 992px) {
  /* style changes when the screen gets smaller */
  .dayExpire {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .flex {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    /* padding: 10px; */
  }
  .flex::after {
    width: 100%;
    content: "";
    display: block;
    /* flex: 0 0 200px; */
    flex-basis: 100%;
    /* background: red; */
  }
  .flex a {
    margin: 5px;
  }
}
