.container {
  width: 70%;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.policyContainer {
  width: 100%;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  padding: 30px;
  border-radius: 16px;
}

.titleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.titleContainer .title {
  font-size: 24px;
}
.policyContainer .content {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.65);
}
.policyContainer .point {
  font-size: 18px;
  margin-top: 20px;
  font-weight: bolder;
}
.confirm {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
}
.confrimBtn {
  margin-top: 20px;
  height: 30px;
  width: 110px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  border: none;
  border-radius: 15px;
  margin-right: 5px;
  color: white;
  margin-right: 10px;
  cursor: pointer;
}

.useBtn {
  margin-top: 20px;
  height: 30px;
  width: 300px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  border: none;
  border-radius: 15px;
  margin-right: 5px;
  color: #1d2a57;
  background-color: rgb(255, 210, 45);
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .container {
    width: 90%;
  }
  .policyContainer {
    width: 90%;
  }
  .userContainer {
    display: flex;
    flex-direction: row;
    min-height: 300px;
    align-items: center;
  }
  .userContainer .userImg {
    width: 40%;
    background-position: left bottom;
  }

  .policyContainer {
    margin-top: 20px;
  }
  .titleContainer {
    font-size: 16px;
  }
  .titleContainer .title {
    font-size: 20px;
  }
  .titleContainer .title p {
    font-size: 16px;
  }
  .policyContainer .point {
    font-size: 16px;
  }
  .confirm {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .confrimBtn {
    margin-top: 5px;
  }
}
