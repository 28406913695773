.btn {
  box-sizing: border-box;
  width: 100%;
  border: none;
  margin: 5px 0;
  font-size: 17px;
  background-color: #3b5998;
  height: 20px;
  color: white;
  margin-left: 10px;
  cursor: pointer;
}

.fb {
  border-radius: 4px;

  background-color: #3b5998;
  opacity: 1;
  /* width: 250px; */
  width: max-content;
  display: flex;
  color: white;
  padding: 10px 20px;
  align-items: center;
  cursor: pointer;
}

.fb:hover {
  background: #2b3647;
}

.fb:hover .btn {
  background: #2b3647;
  font-weight: 600;
}
