.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 40px;
  min-height: 30px;
  margin: 0px 5px;
  font-size: 25px;
  font-weight: 500;
  /* background: white; */
}
.underLine {
  width: 40px;
  height: 4px;
  background-color: #37474f;
  margin: 0 5px;
  margin-bottom: 5px;
}
@media screen and (max-width: 992px) {
  .container {
    width: 30px;
    min-height: 20px;
    margin: 5px 5px;
    font-size: 22px;
    font-weight: 500;
  }
  .underLine {
    width: 30px;
    height: 3px;
    background-color: #37474f;
    margin: 0 3px;
    margin-bottom: 3px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    width: 25px;
    min-height: 20px;
    margin: 5px 2px;
    font-size: 20px;
    font-weight: 500;
  }
  .underLine {
    width: 20px;
    height: 2px;
    background-color: #37474f;
    margin: 0 3px;
    margin-bottom: 3px;
  }
}
