.container {
  width: 100vw;
  height: calc(100vh - 65px);
  display: flex;
  justify-content: space-evenly;
  padding: 50px 20px;
}

.userContainer {
  width: 30%;
}
/* .policyContainer,
.userContainer {
   min-height: 400px; 
} */

.policyContainer {
  width: 60%;
  color: rgba(0, 0, 0, 0.65);
}

.titleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.titleContainer .title {
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 10px;
}

.titleContainer p {
  font-style: italic;
  font-size: 18px;
}
.policyContainer .content {
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.65);
}

.policyContainer .content p {
  font-size: 18px;
}

.policyContainer .content .point {
  font-size: 20px;
  margin-top: 20px;
  /* margin-bottom: 10px; */
  /* line-height: 20px; */
  font-weight: bolder;
}

.userContainer .userImg {
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.userInfor {
  margin-top: 30px;
}
.userInfor .pTag {
  line-height: 30px;
  font-size: 20px;
  word-wrap: break-word;
}
.userInfor .pTag .spanTag {
  font-weight: bold;
  margin-right: 10px;
}
.useBtn {
  margin-top: 20px;
  height: 30px;
  width: 300px;
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  padding: 0 5px;
  border: none;
  border-radius: 15px;
  margin-right: 5px;
  color: #1d2a57;
  background-color: rgb(255, 210, 45);
  margin-right: 10px;
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .policyContainer,
  .userContainer {
    width: 90%;
  }
  .userContainer {
    display: flex;
    flex-direction: row;
    min-height: 300px;
    /* align-items: flex-end; */
    align-items: center;
  }
  .userContainer .userImg {
    width: 40%;
    background-position: left bottom;
  }
  .userContainer .userInfor {
    width: 60%;
    padding: 20px 10px;
    font-size: 16px;
    padding-top: 50%;
  }
  .userInfor .pTag {
    font-size: 14px;
  }
  .userInfor .pTag .spanTag {
    font-weight: bold;
    margin-right: 5px;
    font-size: 14px;
  }
  .policyContainer {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .titleContainer {
    font-size: 16px;
  }
  .titleContainer .title {
    font-size: 20px;
  }
  .titleContainer p {
    font-size: 16px;
  }
  .policyContainer .point {
    font-size: 16px;
  }
  .useBtn {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 375px) {
  .userContainer {
    margin-top: 50%;
  }
  .useBtn {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 350px) {
  .container {
    margin-top: 100px;
  }
}
