.container {
  width: 100%;
  /* max-width: 1140px;    */
  /* position: relative; */
  /* position: absolute; */
  display: flex;
  justify-content: center;
  top: 0;
  margin: auto;
  align-items: center;
  /* overflow: hidden; */
  padding: 50px;
  background: #ffffff;
  min-height: 100vh;
}
.body {
  margin: 0;
  display: flex;
  background: #263238;
}

.position-fixed {
  position: fixed !important;
}
/* .sidebar {
  margin: 0;
 
} */
@media screen and (max-width: 576px) {
  /* style changes when the screen gets smaller */
  .container {
    width: 100vw;
    max-width: 1140px;
    /* position: relative; */
    /* position: absolute; */
    display: flex;
    justify-content: center;
    top: 0;
    margin: 0 auto;
    padding: 10px;
  }
}
