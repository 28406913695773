.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  background-color: #2b3647;
  opacity: 1;
  width: 100%;
}
.nav {
  height: 65px;
  max-width: 1142px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  position: relative;
  opacity: 1;
  z-index: 10;
}
.menu_container {
  display: flex;
  justify-content: center;
  /* align-items: center !important; */
  background: white;
}
.menu {
  display: flex;
  list-style-type: none;
  align-items: center;
  /* background: #000; */
  height: 100%;
  margin: 0;
  padding: 0;
}
.link {
  text-decoration: none;
  margin: 0 20px;
  color: #ffffff;
}
.link:hover,
.active {
  color: rgb(255, 210, 45);
  /* transform: scale(1.1); */
  text-decoration: none;
}
/* responsive */
.menu_collapse {
  display: none;
}
.mobie-container {
  background: #fff;
  border: 20px solid #666;
  border-bottom-width: 80px;
  border-radius: 20px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  height: 520px;
  margin: 0 30px;
  position: relative;
  width: 320px;
}
.mobie-container::before {
  background: #555;
  border: 2px solid #4a4a4a;
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  height: 50%;
  width: 50px;
  height: 50px;
  transform: translateX(-50%);
  bottom: -67px;
  border-radius: 50%;
  z-index: 1;
}
.content {
  background-color: aliceblue;
  display: block;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.nav_menu_collapse {
  background: #fff;
  border-bottom: 1px solid #ddd;
  height: 60px;
  position: relative;
}
.toggle_btn .nav_links {
  display: block;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
}
.toggle_btn .nav_links::before {
  background: rgba(0, 0, 0, 0.5);
  display: block;
  height: 100vh;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  transition: all 0.3s;
  visibility: hidden;
  position: absolute;
}
.toggle_btn .link {
  border-bottom: 1px solid #ddd;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transform: all 0.3s;
}
.toggle_btn span {
  background: #fff;
  color: #404040;
  display: block;
  font-size: 18px;
  font-weight: 700;
  padding: 12px 20px;
  text-align: left;
  width: 100%;
  transition: all 0.3s;
}
.toggle_btn span:hover {
  color: coral;
}
.toggle_btn[type="checkbox"] {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.toggle_btn:checked + .toggle_btn_label {
  border: 4px solid silver;
  border-radius: 50%;
  height: 28px;
  top: 16px;
  left: 18px;
  width: 28px;
  transform: rotate(-135deg);
}
.toggle_btn:checked + .toggle_btn_label::before {
  background: silver;
  top: 8px;
  left: 4px;
  width: 12px;
}
.toggle_btn:checked + .toggle_btn_label::after {
  background: silver;
  opacity: 1;
  top: 8px;
  left: 4px;
  visibility: visible;
  width: 12px;
}

.toggle_btn:checked + .toggle_btn_label:hover {
  border-color: coral;
}
.toggle_btn:checked + .toggle_btn_label:hover::before,
.toggle_btn:checked + .toggle_btn_label:hover::after {
  background: coral;
}
.toggle_btn[type="checkbox"]:checked ~ .nav_links::before {
  opacity: 1;
  visibility: visible;
}

.toggle_btn[type="checkbox"]:checked ~ .nav_links .link {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.toggle_btn::before {
  background: silver;
  top: 8px;
  left: 4px;
  width: 12px;
}
.toggle_btn::after {
  background: silver;
  opacity: 1;
  top: 8px;
  left: 4px;
  visibility: visible;
  width: 12px;
}
.toggle_btn:hover {
  border-color: coral;
}
.toggle_btn:hover::before,
.toggle_btn:hover::after {
  background: coral;
}
.mobie_container .toggle_btn_label {
  background: none transparent;
  border: 4px solid coral;
  border-left: 0 solid transparent;
  border-right: 0 solid transparent;
  cursor: pointer;
  display: block;
  height: 24px;
  position: absolute;
  top: 18px;
  left: 20px;
  width: 24px;
  transition: all 0.2s;
}
.mobie_container .toggle_btn_label::before {
  background: coral;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 24px;
  transition: all 0.2s;
}
.mobie_container .toggle_btn_label::after {
  background: coral;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 6px;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  transform: rotate(90deg);
  transition: all 0.2s;
}

@media screen and (max-width: 576px) {
  /* .nav {
    max-width: none;
    font-size: 16px;
  }
  .menu {
    display: none;
  }
  .menu_collapse {
    display: block;
  }
  .nav_toggle {
    outline: none;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    cursor: pointer;
  }
  .menu_collapse_link_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    height: 0;
    width: 100%;
    background-color: #2b3647;
    opacity: 0;
    transition: 0.3;
  }
  .h_0 {
    opacity: 0;
    transition: 0.3;
    height: 0;
  }
  .h_100 {
    height: calc(100vh - 65px);
    overflow: hidden;
    opacity: 1;
    transition: 0.3;
  }
  .menu_collapse_link_container .link {
    display: none;
  }
  .h_100 .link {
    display: block;
    text-align: center;
  } */
  .menu_collapse {
    display: block;
  }
}
