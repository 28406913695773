.backDrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  overflow: hidden;
  height: 100vh;
}
.backDrop .modal_content {
  /* transform: translateY(0px); */
  transition: 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
}

.backDrop.active {
  transition-duration: 250ms;
  transition-delay: 0ms;
  opacity: 1;
}
.modal_postion {
  transform: translateX(-50%);
  position: fixed;
  top: 0px;
  left: 50%;
}
.backDrop.active .modal_content {
  position: fixed;
  top: 30%;
  /* transform: translateY(0); */
  opacity: 1;
  /* transition-delay: 150ms; */
  /* transition-duration: 350ms; */
  /* position: fixed;
  top: 200px;
  left: 50%; */
  /* transform: translateX(-50%);r */
  padding: 20px;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 50px;
  max-height: 80%;
  max-width: 80%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: white;
  border-radius: 5px;
  /* transition-duration: 3000ms; */
  animation: transitionAnimation 0.3s;
  z-index: 11;
}

@keyframes transitionAnimation {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 576px) {
  .modal_content {
  }
}
