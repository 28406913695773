.container {
  width: 100%;
  /* max-width: 1140px; */
  /* overflow: hidden; */
  /* font-size: 27px; */
  display: flex;
  height: calc(100vh - 65px);
  overflow: hidden;
}
.listening_container {
  padding: 20px 50px;
  height: 100%;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress_line {
  height: 16px;
  max-width: 950px;
  width: 90%;
  min-height: auto;
  min-width: auto;
  border-radius: 8px;
  background-color: #e5e5e5;
  /* overflow: hidden; */
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px; */
  margin: 50px auto;
  position: relative;
}
.progress_line_correct {
  border-radius: 8px;

  background-color: #58a700;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transition: 1s;
}
/* question_box */

.question_box {
  width: 100%;
  padding: 0 20px;
  /* height: 300px; */
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}
.question_box .question {
  font-size: 20px;
  color: #1d2a57;
  font-weight: 400;
}
.listening_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.question_box i {
  font-size: 70px;
  cursor: pointer;
}
.word_result {
  display: flex;
  max-width: 50vw;
  flex-wrap: wrap;
  /* width: 90vw;
   */
  /* align-items: center; */
  /* justify-content: space-between; */
  justify-content: space-evenly !important;
  /* background: red; */
}
.word_result::after {
  content: "";
  flex: auto;
}
.audio_container {
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-bottom: 30px;
}
.audio_container .us,
.audio_container .uk {
  cursor: pointer;
}
.word_input {
  margin-top: 20px;
  width: 500px;
  height: 50px;
  outline: none;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  box-shadow: inset -4px -4px 8px 0 rgba(#fff, 0.8), inset 4px 4px 8px 0 rgba(#666, 0.1);
  border: none;
  border-radius: 8px;
  background-color: #ffffff;
  transition: 0.2s;
  margin-bottom: 15px;
}
.btn {
  width: 150px;
  height: 50px;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
}
.showStatic_container {
  position: absolute;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row-reverse;
  height: max-content;
  color: #1d2a57;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
}

.showStatic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: max-content;
  color: #1d2a57;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
  margin-right: 10px;
}
.showStatic p {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 992px) {
  .word_result {
    max-width: 90vw;
  }
  /* .word_input {
  } */
}
@media screen and (max-width: 576px) {
  .listening_container {
    padding: 10px;
    padding-top: 50px;
  }
  .listening_container span {
    font-size: 15px;
  }
  .question_box {
    margin-top: 30px;
  }
  .progress_line {
    height: 16px;
    max-width: 950px;
    width: 90%;
  }
  .word_result {
    max-width: 90vw;
  }
  .audio_container {
    width: 40%;
  }
  .btn {
    width: 135px;
    height: 45px;
  }
  .question_box i {
    font-size: 40px;
    cursor: pointer;
  }
}
