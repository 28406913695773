.tootip_container {
  /* width: 130px; */
  justify-content: space-between;
  background-color: cadetblue;
  border-radius: 5px;
  z-index: 99;
  color: #bbb;
  width: 140px;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  text-shadow: 1px 1px 2px #111;
  background: rgba(51, 51, 51, 0.9);
  border: 1px solid rgba(34, 34, 34, 0.9);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  display: flex;
  transform: translateX(-50%);
  position: relative;
  top: -25px;
}
/* .tooltip_container::before, */
.tootip_container::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(51, 51, 51, 0.9);
  position: absolute;
  bottom: -10px;
  left: 43%;
  display: block;
}
.container_reading {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.reading_txt {
  /* position: relative; */
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
}

.reading_container {
  padding-top: 50px;
  position: relative;
  min-height: 100vh;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  width: 90%;
}
.reading_content {
  padding: 30px;
  width: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  border-radius: 5px;
  border: solid 1px black;
}
.title {
  margin: 20px 0;
  font-size: 24px;
}

.fix_height {
  height: 90px;
  width: 100%;
}
.choices_container {
  padding: 10px;
  width: 100%;
  height: 50vh;
}
.vocaburyModal {
  font-size: 25px;
  text-transform: capitalize;
}
.containerModal {
  min-height: 200px;
  position: relative;
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.btnTranslate {
  position: absolute;
  right: 5px;
  top: 5px;
}
.definitionModal,
.exampleModal {
  font-size: 18px;
  margin: 15px 0;
}
.definitionModal::first-letter,
.exampleModal::first-letter {
  text-transform: capitalize;
}
.btnQuestion {
  width: 150px;
  height: 50px;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
}
.centerPositon {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* disable tooltip */
.tootip_container {
  display: none;
}
.question_container {
  margin-top: 10px;
  position: relative;
}
.backDrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
}
@media screen and (max-width: 992px) {
  .containerModal {
    width: 100%;
    max-width: 600px;
    min-width: 400px;
  }
}
@media screen and (max-width: 576px) {
  .container {
    padding: 10px;
  }
  .reading_txt {
    font-style: 16px;
  }
  .reading_container {
    padding-top: 20px;
    width: 100%;
  }
  .reading_content {
    font-style: 16px;
    padding: 10px;
  }
  .title {
    font-size: 22px;
  }
  .choices_container .title {
    font-style: 18px !important;
  }
  .vocaburyModal {
    font-size: 20px;
  }
  .containerModal {
    min-width: 100%;
    width: 100%;
  }
  .definitionModal,
  .exampleModal {
    font-size: 16px;
  }
  .btnQuestion {
    width: 135px;
    height: 45px;
  }
}
