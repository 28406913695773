* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Quicksand", sans-serif;
}
.show {
  opacity: 1 !important;
}
